<template>
  <page-wrapper class="algemene-voorwaarden">
    <h1>Algemene Voorwaarden</h1>
    <p>
      <a
        href="/algemene-voorwaarden.pdf"
        target="_blank"
        download="Algemene Voorwaarden - Jolande Venema.pdf"
        >Download de algemene voorwaarden
      </a>
    </p>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";

export default {
  metaInfo: {
    title: "Algemene Voorwaarden",
  },
  components: {
    PageWrapper,
  },
};
</script>

<style lang="scss" scoped>
.algemene-voorwaarden {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
  }
}
</style>
